import * as actionTypes from "./constant";
const addGeneralInformation = (value) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ADD_PROJECT_DATA_INIT_ACTION,
      value: value,
    });
  };
};

const addProject = (value, generalInfoList, projectSegments) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ADD_PROJECTS_INIT_ACTION,
      value: value,
      generalInfoList: generalInfoList,
      projectSegment: projectSegments,
    });
  };
};

const updateProjectData = (projectData) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_PROJECTS_DATA_INIT_ACTION,
      projectData: projectData,
    });
  };
};

const fetchProjectById = (value) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_PROJECTBYID_INIT_ACTION,
      value: value,
    });
  };
};

export default {
  addGeneralInformation,
  addProject,
  updateProjectData,
  fetchProjectById,
};
