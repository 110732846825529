import * as actionTypes from "./constant";

const initialState = {
  processing: false,
  error: false,
  countryList: [],
  countryCount: 0,
};

export default function CountryReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_COUNTRY_SUCCESS_ACTION:
      return {
        ...state,
        countryList: action.payload.rows,
        countryCount: action.payload.count,
      };
    case actionTypes.UPDATE_COUNTRY_ACTION:
      return {
        ...state,
        countryList: [],
        countryCount: 0,
      };

    case actionTypes.EDIT_COUNTRY_SUCCESS_ACTION:
      return {
        ...state,
        countryList: state.countryList.map((list) =>
          list.id === action.payload.id ? action.payload : list
        ),
        countryCount: state.countryCount,
      };
    default:
      return {
        ...state,
      };
  }
}
