import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { connectRouter } from "connected-react-router";
import storage from "redux-persist/es/storage";

import DashboardReducer from "../containers/DefaultLayout/reducer";

import store, { history } from "../boot/configureStore";

import CreateProjectReducer from "../containers/CreateProjectContainer/reducer";
import MyProjectReducer from "../containers/LandingPageContainer/reducer";
import forgotPasswordReducer from "../containers/ForgotPasswordContainer/reducer";
//admin
import CountryReducer from "../containers/AdminContainer/CountryContainer/reducer";
import CurrencyReducer from "../containers/AdminContainer/CurrencyContainer/reducer";
import UserReducer from "../containers/AdminContainer/UserContainer/reducer";
import CountryCurrencyReducer from "../containers/AdminContainer/CountryCurrencyContainer/reducer";
import ProjectTypeReducer from "../containers/AdminContainer/ProjectTypeContainer/reducer";
import DevelopmentStageReducer from "../containers/AdminContainer/DevelopmentStageContainer/reducer";
import InterventionTypeReducer from "../containers/AdminContainer/InterventionTypeContainer/reducer";
import UnitReducer from "../containers/AdminContainer/UnitContainer/reducer";
import EmissionFactorTypeReducer from "../containers/AdminContainer/EmissnFactorTypeContainer/reducer";
import EmissionFactorSubTypeReducer from "../containers/AdminContainer/EmissionFactorSubtypeContainer/reducer";
import MiscellaneousDefaultValueReducer from "../containers/AdminContainer/MiscellaneousContainer/reducer";
import EFSession2Reducer from "../containers/AdminContainer/EmissionFactorSession2Container/reducer";
import fetchEmissionFactorSessionReducer from "../containers/AdminContainer/EmissionFactorSessionContainer/reducer";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),

    DashboardReducer,

    CreateProjectReducer,
    MyProjectReducer,
    forgotPasswordReducer,
    //admin
    CountryReducer,
    CurrencyReducer,
    UserReducer,
    CountryCurrencyReducer,
    ProjectTypeReducer,
    DevelopmentStageReducer,
    InterventionTypeReducer,
    UnitReducer,
    EmissionFactorTypeReducer,
    MiscellaneousDefaultValueReducer,
    EmissionFactorSubTypeReducer,
    EFSession2Reducer,
    fetchEmissionFactorSessionReducer,
  });

export default createRootReducer;
