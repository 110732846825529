import { FETCH_GETEQUATION_SUCCESS_ACTION } from "./action";

const initialState = {
  processing: false,
  error: false,
};

export default function DashboardReducer(
  state: any = initialState,
  action: Function
) {
  switch (action.type) {
    case FETCH_GETEQUATION_SUCCESS_ACTION:
      return {
        ...state,
        projectValue: action.payload.project.valueInTCO2e,
        projectTotalValue: action.payload.project.total,
      };

    default:
      return {
        ...state,
      };
  }
}
