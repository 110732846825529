import * as actionTypes from "./constant";
import { put, takeEvery, call } from "redux-saga/effects";
import { appConfig } from "../../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function* commonFunction(value) {
  const Psbtoken = localStorage.getItem("Psbtoken");
  try {
    const response = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${Psbtoken}`,
      },
      body: value.body ? value.body : null,
    });

    if (!response.ok) {
      throw response;
    } else {
      const resJSON = yield response.json();

      yield put({
        type: `${value.successAction}`,
        payload: resJSON,
      });
      if (value.extraAction) {
        yield put({
          type: `${value.extraAction}`,
          payload: resJSON,
        });
      }
    }
  } catch (error) {
    yield put({ type: `${value.failAction}`, error: error });
  }
}

function* fetchProjectList(value) {
  let user = JSON.parse(localStorage.getItem("PsbUser"));
  let searchVal =
    value && value.value && value.value.searchVal ? value.value.searchVal : "";

  let url = "";
  if (user.role === "user") {
    url = `&userId=${user.id}&status=draft,publish`;
  }
  let page = (value.value && value.value.page) || 1;
  // let id = value.value.id;

  // let urls = "";

  // if (id) {
  //   urls = `/project/${id}`;
  // } else {
  //   urls = `/project?ser=${searchVal}&limit=10&page=${page}${url}`;
  // }

  try {
    let params = {
      page: page,
      api: `${appConfig.ip}/project?ser=${searchVal}&limit=10&page=${page}${url}`,
      method: "GET",
      successAction: actionTypes.FETCH_PROJECTLIST_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_PROJECTLIST_FAIL_ACTION,
    };
    yield call(commonFunction, params);
  } catch (error) {}
}

function* changeStatus(value) {
  let id = value.value.id;
  let data = {
    // userId: value.value.userId,
    // name: value.value.name,
    ...value.value,
    status:
      value.value.status === "draft"
        ? "publish"
        : "deleted"
        ? "draft"
        : "draft",
  };

  try {
    let params = {
      api: `${appConfig.ip}/project/${id}`,
      method: "PUT",
      successAction: actionTypes.CHANGE_STATUS_PROJECT_SUCCESS_ACTION,
      failAction: actionTypes.CHANGE_STATUS_PROJECT_FAIL_ACTION,
      // extraAction: actionTypes.FETCH_PROJECTLIST_INIT_ACTION,
      body: JSON.stringify(data),
    };
    yield call(commonFunction, params);
    let res = yield call(commonFunction, params);
    if (res) {
      yield call(id);
    }
    yield toast.success("Status updated successfully", {
      autoClose: 3000,
    });
  } catch (err) {}
}

// function* deleteProjects(item) {
//   const id = item.item.id;
//   // const data = { ...item.item, status: "delete" };

//   try {
//     let params = {
//       api: `${appConfig.ip}/project/${id}`,
//       method: "DELETE",
//       successAction: actionTypes.DELETE_PROJECT_SUCCESS_ACTION,
//       failAction: actionTypes.DELETE_PROJECT_FAIL_ACTION,
//       extraAction: actionTypes.FETCH_PROJECTLIST_INIT_ACTION,
//     };
//     yield call(commonFunction, params);
//     yield toast.success("Project deleted successfully", {
//       autoClose: 3000,
//     });
//   } catch (error) {

//   }
// }

function* deleteProjects(item) {
  const Psbtoken = localStorage.getItem("Psbtoken");
  const PsbUser = JSON.parse(localStorage.getItem("PsbUser"));

  let id = item.item.id;

  try {
    const res = yield fetch(`${appConfig.ip}/project/` + id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${Psbtoken}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      yield put({
        type: actionTypes.DELETE_PROJECT_SUCCESS_ACTION,
        payload: id,
        PsbUser: PsbUser,
      });
      yield toast.success("Deleted successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: actionTypes.DELETE_PROJECT_FAIL_ACTION, error: err });
    } else {
    }
  }
}

export function* MyProjectActionWatcher() {
  yield takeEvery(actionTypes.FETCH_PROJECTLIST_INIT_ACTION, fetchProjectList);
  yield takeEvery(actionTypes.CHANGE_STATUS_PROJECT_INIT_ACTION, changeStatus);
  yield takeEvery(actionTypes.DELETE_PROJECT_INIT_ACTION, deleteProjects);
}
