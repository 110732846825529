import * as actionTypes from "./constant";

const initialState = {
  processing: false,
  error: false,
};

export default function MyProjectReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_PROJECTLIST_SUCCESS_ACTION:
      return {
        ...state,
        projectList: action.payload.rows,
        projectCount: action.payload.count,
      };

    case actionTypes.CHANGE_STATUS_PROJECT_SUCCESS_ACTION:
      return {
        ...state,
        projectList: state.projectList.map((list) =>
          list.id === action.payload.id ? action.payload : list
        ),
        projectCount: state.projectCount,
      };

    case actionTypes.DELETE_PROJECT_SUCCESS_ACTION:
      let updatedProjectList;
      if (action.PsbUser.role && action.PsbUser.role === "admin") {
        updatedProjectList = state.projectList.map((item) =>
          item.id === action.payload ? { ...item, status: "deleted" } : item
        );
      } else {
        updatedProjectList = state.projectList.filter(
          (item) => item.id !== action.payload
        );
      }
      return {
        ...state,

        projectList: updatedProjectList,
        projectCount: state.projectCount,
      };

    default:
      return {
        ...state,
      };
  }
}
