import { takeEvery, put, call } from "redux-saga/effects";
import * as actionTypes from "./constant";
import { appConfig } from "../../config";
import { toast, Flip } from "react-toastify";
import { push } from "connected-react-router";

function* commonFunction(value, toastSuccess = false, toastError = true) {
  var btoa = require("Base64").btoa;
  try {
    const response = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: value.body ? value.body : null,
    });

    if (!response.ok) {
      throw response;
    } else {
      const resJSON = yield response.json();

      yield put({
        type: `${value.successAction}`,
        payload: resJSON,
      });

      if (value.extraAction) {
        yield put({
          type: `${value.extraAction}`,
          payload: resJSON,
        });
      }
      if (value.body.pin !== "") {
        if (resJSON.ok === false && resJSON.attemptLeft >= 0) {
          throw resJSON;
        } else if (resJSON.ok === false && resJSON.attemptLeft < 0) {
          throw resJSON;
        }
      }
    }
  } catch (error) {
    console.log("error", error);
    let message;
    if (error.status === 400) {
      message = "Field must not be empty and Enter a valid input";
    } else if (error.status === 401) {
      message = "Undefined";
    } else if (error.status === 403) {
      message = "Forbidden";
    } else if (error.status >= 500) {
      message = "Server Error";
    } else if (error.ok === false && error.attemptLeft >= 0) {
      message =
        "You have entered a wrong OTP. You have" +
        " " +
        error.attemptLeft +
        " " +
        "attempts left";
    } else if (error.status === 404) {
      message = "User not found!! Try again with the registered email";
    } else {
      message = "Unknown Error";
    }

    yield put({ type: `${value.failAction}`, error: error });

    if (toastError) {
      yield toast.error(
        `${message}`,
        { autoClose: 3000 },
        { transition: Flip }
      );
    }
    throw error;
  }
}

function* otpVerifyForgotPassword(value) {
  const data = { email: value.value.email, pin: value.value.otp };
  try {
    let params = {
      api: `${appConfig.ip}/password-resets/checkpin`,
      method: "POST",
      failAction: actionTypes.OTP_VERIFICATION_FAIL_ACTION,
      successAction: actionTypes.OTP_VERIFICATION_SUCCESS_ACTION,
      body: JSON.stringify(data),
    };
    yield call(commonFunction, params, true);

    yield toast.success("Verified successfully", {
      autoClose: 3000,
    });
    // yield put(
    //   push("/forgotpassword?tab=resetPass", { email: value.value.email })
    // );
  } catch (err) {
    console.log(err);
    if (err.attemptLeft === 0) {
      yield toast.error(
        "Redirecting to login page, since you have used your maximum attempts",
        {
          autoClose: 3000,
          transition: Flip,
          className: "toastClass",
        }
      );
      yield put({
        type: actionTypes.REDIRECT_TO_LOGIN_INIT_ACTION,
      });
    }
  }
}

function* sendOtpForgotPassword(value) {
  const data = { email: value.value.user && value.value.user };
  try {
    let params = {
      api: `${appConfig.ip}/password-resets/sendotp`,
      method: "POST",
      failAction: actionTypes.FORGOT_SEND_OTP_PASSWORD_FAIL_ACTION,
      successAction: actionTypes.FORGOT_SEND_OTP_PASSWORD_SUCCESS_ACTION,
      body: JSON.stringify(data),
    };
    yield call(commonFunction, params, true);
    yield toast.success("An OTP has been sent to the registered email", {
      autoClose: 3000,
    });
    // yield put(
    //   push("/forgotpassword?tab=verifyOtp", { email: value.value.user })
    // );
  } catch (err) {
    console.log(err);
  }
}

function* resendOtpForgotPassword(value) {
  const data = { email: value.value.user };

  try {
    let params = {
      api: `${appConfig.ip}/password-resets/resendotp`,
      method: "POST",
      failAction: actionTypes.FORGOT_RESEND_OTP_PASSWORD_FAIL_ACTION,
      successAction: actionTypes.FORGOT_RESEND_OTP_PASSWORD_SUCCESS_ACTION,
      body: JSON.stringify(data),
    };
    yield call(commonFunction, params, true);
    yield toast.success("OTP resend to the registered  email", {
      autoClose: 3000,
    });
    // yield put(
    //   push("/forgotpassword?tab=verifyOtp", { email: value.value.user })
    // );
  } catch (err) {
    console.log(err);
  }
}

function* reSetNewpassword(value) {
  console.log(
    "JSON.parse(localStorage)",
    JSON.parse(localStorage.getItem("PsbUser"))
  );

  const data = {
    email: value.value.email,
    pin: value.value.otp,
    password: value.value.password,
  };
  try {
    let params = {
      api: `${appConfig.ip}/password-resets/change`,
      method: "PUT",
      failAction: actionTypes.RESET_NEW_PASSWORD_FAIL_ACTION,
      successAction: actionTypes.RESET_NEW_PASSWORD_SUCCESS_ACTION,
      body: JSON.stringify(data),
    };
    yield call(commonFunction, params, true);
    yield toast.success("Password changed successfully", {
      autoClose: 3000,
    });
    yield put(push("/login"));
  } catch (err) {
    console.log(err);
  }
}
function* redirectTologin() {
  yield put({
    type: actionTypes.REDIRECT_TO_LOGIN_SUCCESS_ACTION,
  });
  yield put(push("/login"));
}

export function* ForgotResetPwdActionWatcher() {
  yield takeEvery(
    actionTypes.FORGOT_SEND_OTP_PASSWORD_INIT_ACTION,
    sendOtpForgotPassword
  );
  yield takeEvery(
    actionTypes.FORGOT_RESEND_OTP_PASSWORD_INIT_ACTION,
    resendOtpForgotPassword
  );
  yield takeEvery(
    actionTypes.OTP_VERIFICATION_INIT_ACTION,
    otpVerifyForgotPassword
  );

  yield takeEvery(actionTypes.RESET_NEW_PASSWORD_INIT_ACTION, reSetNewpassword);
  yield takeEvery(actionTypes.REDIRECT_TO_LOGIN_INIT_ACTION, redirectTologin);
}
