import * as actionTypes from "./constant";

const initialState = {
  processing: false,
  error: false,
};

export default function CreateProjectReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ADD_PROJECT_DATA_INIT_ACTION:
      return {
        ...state,
        generalInfoList: action.value,
      };

    default:
      return {
        ...state,
      };
  }
}
