export const ADD_PROJECT_DATA_INIT_ACTION = "ADD_PROJECT_DATA_INIT_ACTION";
export const ADD_PROJECT_DATA_SUCCESS_ACTION =
  ADD_PROJECT_DATA_INIT_ACTION + "_SUCCESS";
export const ADD_PROJECT_DATA_FAIL_ACTION =
  ADD_PROJECT_DATA_INIT_ACTION + "_ERROR";

export const ADD_PROJECTS_INIT_ACTION = "ADD_PROJECTS_INIT_ACTION";
export const ADD_PROJECTS_SUCCESS_ACTION =
  ADD_PROJECTS_INIT_ACTION + "_SUCCESS";
export const ADD_PROJECTS_FAIL_ACTION = ADD_PROJECTS_INIT_ACTION + "_ERROR";

export const UPDATE_PROJECTS_DATA_INIT_ACTION =
  "UPDATE_PROJECTS_DATA_INIT_ACTION";
export const UPDATE_PROJECTS_DATA_SUCCESS_ACTION =
  UPDATE_PROJECTS_DATA_INIT_ACTION + "_SUCCESS";
export const UPDATE_PROJECTS_DATA_FAIL_ACTION =
  UPDATE_PROJECTS_DATA_INIT_ACTION + "_ERROR";

export const FETCH_PROJECTBYID_INIT_ACTION = "FETCH_PROJECTBYID_INIT_ACTION";
export const FETCH_PROJECTBYID_SUCCESS_ACTION =
  FETCH_PROJECTBYID_INIT_ACTION + "_SUCCESS";
export const FETCH_PROJECTBYID_FAIL_ACTION =
  FETCH_PROJECTBYID_INIT_ACTION + "_ERROR";
