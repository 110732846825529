//DirectSeller side rolewise navbar

export const navLicensee = {
  items: [
    {
      name: "Users",
      url: "/masterdata/users",
      icon: "fa fa-users",
    },

    // {
    //   name: "Settings",
    //   url: "/masterdatasettings",
    //   icon: "icon-speedometer",
    //   children: [
    {
      name: "PSB Calculation Data Settings",
      url: "/datasettings",
      //   icon: "fa-solid fa-gears",
      // icon: "fa fa-puzzle-piece",
      icon: "fa fa-cogs",

      children: [
        {
          name: "Emission Factor Type",
          url: "/masterdata/EmissnFactorType",
        },
        {
          name: "Emission Factor Sub type",
          url: "/masterdata/EmissionFactorSubtype",
        },
        {
          name: "Emission Factor Sub Section",
          url: "/masterdata/EFSession2",
        },

        {
          name: "Emission Factor",
          url: "/masterdata/EmissionFactorSession",
        },
      ],
    },

    {
      name: "Additional Master Data Settings",
      url: "/settings",
      //  icon: "fa-solid fa-gears",
      // icon: "fa fa-puzzle-piece",
      icon: "fa fa-cogs",
      children: [
        {
          name: "Country",
          url: "/masterdata/country",
        },
        {
          name: "Currency",
          url: "/masterdata/currency",
        },
        {
          name: "Country Currency",
          url: "/masterdata/Countrycurrency",
        },
        {
          name: "Project Type",
          url: "/masterdata/projecttype",
        },
        {
          name: "Project Development Stage",
          url: "/masterdata/developmentstage",
        },

        {
          name: "Type of Intervention",
          url: "/masterdata/InterventionType",
        },

        {
          name: "Unit",
          url: "/masterdata/Unit",
        },
        // {
        //   name: "Miscellaneous Default Value",
        //   url: "/masterdata/miscelvalue",
        // },
      ],
    },
  ],
};
//   ],
// };
