import { push } from "connected-react-router";
import * as actionTypes from "./constant";
import { put, takeEvery, call } from "redux-saga/effects";
import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function* commonFunction(value) {
  const Psbtoken = localStorage.getItem("Psbtoken");
  try {
    const response = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${Psbtoken}`,
      },
      body: value.body ? value.body : null,
    });
    if (!response.ok) {
      throw response;
    } else {
      const resJSON = yield response.json();

      yield put({
        type: `${value.successAction}`,
        payload: resJSON,
      });
      if (value.extraAction) {
        yield put({
          type: `${value.extraAction}`,
          payload: resJSON,
        });
      }
      return resJSON;
    }
  } catch (error) {
    console.error("error", error);
    yield put({ type: `${value.failAction}`, error: error });
  }
}

function* addProject(value) {
  let user = JSON.parse(localStorage.getItem("PsbUser"));
  const data = {
    userId: user.id,
    name: value.value.name,
    projectType: value.value.projectType,
    projectDevelopmntStage: value.value.projectDevelopmntStage,
    opex: value.value.opex,
    operationPhaseDuration: value.value.operationPhaseDuration,
    interventionType: value.value.interventionType,
    endOfPeriodAnalysis: value.value.endOfPeriodAnalysis,
    dateOfCarbonFootPrint: value.value.dateOfCarbonFootPrint,
    country: value.value.country,
    currency: value.value.currency,
    contactPerson: value.value.contactPerson,
    constructionStartDt: value.value.constructionStartDt,
    capex: value.value.capex,
    desc: value.value.desc,
    refDesc: value.value.refDesc,
    projectSegment: value.value.projectSegment,
  };

  try {
    let params = {
      api: `${appConfig.ip}/project`,
      method: "POST",
      successAction: actionTypes.ADD_PROJECTS_SUCCESS_ACTION,
      failAction: actionTypes.ADD_PROJECTS_FAIL_ACTION,
      body: JSON.stringify(data),
    };

    let res = yield call(commonFunction, params);

    if (res) {
      yield put({
        type: actionTypes.ADD_PROJECT_DATA_INIT_ACTION,
        value: res,
      });
      yield put(push(`/createproject/${res.id}/calculation`));
    }
    yield toast.success("Project added successfully", {
      autoClose: 3000,
    });
  } catch (error) {}
}

function* updateProjectData(value) {
  let id = value.projectData.id;
  let user = JSON.parse(localStorage.getItem("PsbUser"));
  const data = {
    userId: value.projectData.userId,
    name: value.projectData.name,
    projectType: value.projectData.projectType,
    projectDevelopmntStage: value.projectData.projectDevelopmntStage,
    opex: value.projectData.opex,
    operationPhaseDuration: value.projectData.operationPhaseDuration,
    interventionType: value.projectData.interventionType,
    endOfPeriodAnalysis: value.projectData.endOfPeriodAnalysis,
    dateOfCarbonFootPrint: value.projectData.dateOfCarbonFootPrint,
    country: value.projectData.country,
    contactPerson: value.projectData.contactPerson,
    constructionStartDt: value.projectData.constructionStartDt,
    capex: value.projectData.capex,
    desc: value.projectData.desc,
    refDesc: value.projectData.refDesc,
    projectSegment: value.projectData.projectSegment,
    industrialSite: value.projectData.industrialSite,
    currency: value.projectData.currency,
    electricalPowerLine: value.projectData.electricalPowerLine
      ? value.projectData.electricalPowerLine
      : [],
    substation: value.projectData.substation
      ? value.projectData.substation
      : [],
    freight: value.projectData.freight ? value.projectData.freight : [],
    clearing: value.projectData.clearing ? value.projectData.clearing : [],

    operationPhaseEnergy: value.projectData.operationPhaseEnergy
      ? value.projectData.operationPhaseEnergy
      : [],
    refOperationPhaseEnergy: value.projectData.refOperationPhaseEnergy
      ? value.projectData.refOperationPhaseEnergy
      : [],

    operationPhaseProcessEmission: value.projectData
      .operationPhaseProcessEmission
      ? value.projectData.operationPhaseProcessEmission
      : [],
    refOperationPhaseProcessEmission: value.projectData
      .refOperationPhaseProcessEmission
      ? value.projectData.refOperationPhaseProcessEmission
      : [],
    status: value.projectData.status,
  };

  try {
    let params = {
      api: `${appConfig.ip}/project/` + id,
      method: "PUT",
      successAction: actionTypes.UPDATE_PROJECTS_DATA_SUCCESS_ACTION,
      failAction: actionTypes.UPDATE_PROJECTS_DATA_FAIL_ACTION,
      body: JSON.stringify(data),
    };

    let res = yield call(commonFunction, params);

    if (res) {
      yield put({
        type: actionTypes.ADD_PROJECT_DATA_INIT_ACTION,
        value: res,
      });
    }
    // yield toast.success("Project update successfully", {
    //   autoClose: 3000,
    // });
  } catch (error) {}
}

function* fetchProjectById(value) {
  let id = value.value;

  let user = JSON.parse(localStorage.getItem("PsbUser"));
  try {
    let params = {
      api: `${appConfig.ip}/project/${id}`,
      method: "GET",
      successAction: actionTypes.FETCH_PROJECTBYID_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_PROJECTBYID_FAIL_ACTION,
    };
    let res = yield call(commonFunction, params);

    if (res) {
      yield put({
        type: actionTypes.ADD_PROJECT_DATA_INIT_ACTION,
        value: res,
      });
    }
  } catch (error) {}
}

export function* CreateProjectWatcher() {
  yield takeEvery(actionTypes.ADD_PROJECTS_INIT_ACTION, addProject);
  yield takeEvery(
    actionTypes.UPDATE_PROJECTS_DATA_INIT_ACTION,
    updateProjectData
  );
  yield takeEvery(actionTypes.FETCH_PROJECTBYID_INIT_ACTION, fetchProjectById);
}
