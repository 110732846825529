import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import "react-toastify/dist/ReactToastify.css";
import { TextField } from "../Common/TextField";
import "../Login/login.css";
import "./forgotpassword.css";

function ForgotPassword(props) {
  const [email, setEmail] = useState("");
  const [otpSent, setotpSent] = useState();
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType1, setPasswordType1] = useState("password");
  // VALIDATION
  const emailRegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  let validate;
  if (props.selectedTab === "verifyOtp") {
    validate = Yup.object({
      pin: Yup.string().required("OTP is required"),
    });
  } else if (props.selectedTab === "resetPass") {
    validate = Yup.object({
      password: Yup.string()
        .required("This field is required")
        .min(6, "Password must be atleast 6 characters long"),
      confPassword: Yup.string()
        .required("This field is required")
        .oneOf([Yup.ref("password")], "Passwords must match"),
    });
  } else if (props.selectedTab === "sendOtp") {
    validate = Yup.object({
      user: Yup.string()
        .matches(emailRegExp, "Email is not valid")
        .required("Email is required"),
    });
  }

  // SEND OTP
  let sendOtpHandler = (e, formik) => {
    e.preventDefault();
    let email = formik.values.user;
    setEmail(email);
    props.sendOtpForgotPassword({ user: email });
  };
  // RESEND OTP
  const resendOtpforResetPwd = () => {
    props.resendOtpForgotPassword({ user: email });
  };

  // VERIFY OTP
  const verifyOtpHandler = (e, formik) => {
    const otp = formik.values.pin;
    setotpSent(otp);

    props.otpVerifyForgotPassword({
      email: email,
      otp: otp,
    });
  };

  const resetPasswordHandler = (e, formik) => {
    e.preventDefault();
    let password = formik.values.password;
    let rePassword = formik.values.confPassword;

    if (password === rePassword) {
      props.reSetNewpassword({
        email: email,
        password: password,
        otp: otpSent,
      });
    }
  };

  // PASSWORD VISIBILITY
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const togglePassword1 = () => {
    if (passwordType1 === "password") {
      setPasswordType1("text");
      return;
    }
    setPasswordType1("password");
  };

  return (
    <div className="backgroundscreen">
      <div className="bgcolorLogin"> </div>
      <div className="backgroundsecscn">
        <div class="logincontent12"></div>
        <div>
          <Row>
            <Col xs={12} sm={12} md={6} lg={7} xl={8}>
              <div className="loginseondsection">
                <img
                  src={require("../../../src/assets/img/ecostruxure.png")}
                  className="loginimages"
                />
                <div>
                  <p className="logintitle">Project Sustainability Barometer</p>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={5} xl={4}>
              <div className="loginform">
                <Formik
                  initialValues={{
                    UserID: "",
                    email: "",
                    password: "",
                    otp: "",
                  }}
                  validationSchema={validate}
                >
                  {(formik) => (
                    <div>
                      <div>
                        <Form>
                          <div className="formAlign1">
                            <>
                              {" "}
                              <div>
                                {props.selectedTab === "verifyOtp" ? (
                                  <>
                                    {" "}
                                    <h3
                                      className="forgot-title"
                                      style={{ marginLeft: "57px" }}
                                    >
                                      Forgot Your Password?
                                    </h3>
                                    <p style={{ marginLeft: "57px" }}>
                                      {" "}
                                      Enter the OTP you received.
                                    </p>
                                  </>
                                ) : props.selectedTab === "resetPass" ? (
                                  ""
                                ) : (
                                  <>
                                    <h3
                                      className="forgot-title"
                                      style={{ marginLeft: "57px" }}
                                    >
                                      Forgot Your Password?
                                    </h3>
                                    <p style={{ marginLeft: "57px" }}>
                                      {" "}
                                      Enter your email and we'll send you OTP to
                                      reset your password .
                                    </p>
                                  </>
                                )}
                              </div>
                            </>

                            {props.selectedTab === "verifyOtp" ? (
                              <>
                                <div
                                  className="lgn-usrname"
                                  style={{ width: "100%" }}
                                >
                                  <TextField
                                    placeholder="Enter the OTP"
                                    name="pin"
                                    id="number-input"
                                    type="number"
                                    autoComplete="off"
                                    // required
                                  />
                                </div>
                                <div>
                                  <Link
                                    to="/forgotpassword"
                                    className="resetforgot"
                                    onClick={resendOtpforResetPwd}
                                  >
                                    Resend OTP?
                                  </Link>
                                </div>
                                <Button
                                  type="submit"
                                  className="contactpagebtn"
                                  onClick={(e) => verifyOtpHandler(e, formik)}
                                >
                                  VERIFY OTP
                                </Button>
                              </>
                            ) : props.selectedTab === "resetPass" ? (
                              <>
                                <div
                                  className="lgn-usrname"
                                  style={{ width: "100%" }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <TextField
                                      placeholder="Enter the new password"
                                      name="password"
                                      type={passwordType1}
                                    />
                                    <span className="pswd-lgn">
                                      {passwordType1 === "password" ? (
                                        <i
                                          class="fa fa-eye-slash pswd-lgn-icon"
                                          aria-hidden="true"
                                          onClick={togglePassword1}
                                        ></i>
                                      ) : (
                                        <i
                                          class="fa fa-eye pswd-lgn-icon"
                                          aria-hidden="true"
                                          onClick={togglePassword1}
                                        ></i>
                                      )}
                                    </span>
                                  </div>
                                  <div style={{ display: "flex" }}>
                                    <TextField
                                      placeholder="Re-enter the password"
                                      name="confPassword"
                                      type={passwordType}
                                    />
                                    <span className="pswd-lgn">
                                      {passwordType === "password" ? (
                                        <i
                                          class="fa fa-eye-slash pswd-lgn-icon"
                                          aria-hidden="true"
                                          onClick={togglePassword}
                                        ></i>
                                      ) : (
                                        <i
                                          class="fa fa-eye pswd-lgn-icon"
                                          aria-hidden="true"
                                          onClick={togglePassword1}
                                        ></i>
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <Button
                                  type="submit"
                                  className="contactpagebtn"
                                  onClick={(e) =>
                                    resetPasswordHandler(e, formik)
                                  }
                                >
                                  RESET PASSWORD
                                </Button>
                              </>
                            ) : (
                              <>
                                <div
                                  className="lgn-usrname"
                                  style={{ width: "100%" }}
                                >
                                  <TextField
                                    placeholder="Enter Your Email"
                                    name="user"
                                    type="text"
                                  />
                                </div>
                                <Button
                                  type="submit"
                                  className="contactpagebtn"
                                  onClick={(e) => sendOtpHandler(e, formik)}
                                >
                                  SEND OTP
                                </Button>
                              </>
                            )}
                          </div>
                          <div className="backtologin">
                            <Link to="/login" className="loginforgot">
                              Back to login
                            </Link>
                          </div>
                        </Form>
                      </div>
                    </div>
                  )}
                </Formik>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="footerclass">
        <React.Fragment>
          <Row>
            <Col xs={12} sm={12} md={8} lg={8}>
              <span>
                Copyright ©2023 Schneider Electric.All Rights Reserved
              </span>
            </Col>
            <Col xs={12} sm={12} md={4} lg={4}>
              <img
                src={require("../../../src/assets/img/schneider-logo.png")}
                className="loginfooterimages"
              />
            </Col>
          </Row>
        </React.Fragment>
      </div>
    </div>
  );
}

export default ForgotPassword;
