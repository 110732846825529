import React, { Component, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import * as router from "react-router-dom";
import { Container } from "reactstrap";
import { connect } from "react-redux";
import { logOut } from "../../containers/LoginContainer/actions";
import { pusher } from "../../service/pusher";

import {
  AppAside,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppBreadcrumb2 as AppBreadcrumb,
  AppSidebarNav2 as AppSidebarNav,
} from "@coreui/react";

import { navLicensee } from "../../_nav";
import routes from "../../routes";
import { fetchGetEquation } from "../../containers/DefaultLayout/action";
import Action from "../CreateProjectContainer/action";

const DefaultAside = React.lazy(() => import("./DefaultAside"));
const DefaultFooter = React.lazy(() => import("./DefaultFooter"));
const DefaultHeader = React.lazy(() => import("./DefaultHeader"));

class DefaultLayout extends Component {
  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  signOut(e) {
    let user = JSON.parse(localStorage.getItem("PsbUser"));
    pusher.unsubscribe(user.id);
    e.preventDefault();
    this.props.logoutaction();
  }

  componentDidMount = () => {
    this.props.fetchGetEquation();
  };

  render() {
    let user = JSON.parse(localStorage.getItem("PsbUser"));
    return (
      <div className="app">
        <AppHeader fixed>
          <Suspense fallback={this.loading()}>
            <DefaultHeader
              onLogout={(e) => this.signOut(e)}
              location={this.props.location}
              addGeneralInformation={this.props.addGeneralInformation}
            />
          </Suspense>
        </AppHeader>
        <div className="app-body">
          {user &&
            user.role === "admin" &&
            this.props.location.pathname.includes("masterdata") && (
              <AppSidebar fixed display="lg">
                <AppSidebarHeader />
                <AppSidebarForm />
                <Suspense>
                  <AppSidebarNav
                    navConfig={navLicensee}
                    {...this.props}
                    router={router}
                  />
                </Suspense>
                <AppSidebarFooter />
                <AppSidebarMinimizer />
              </AppSidebar>
            )}
          <main className="main">
            {/* <AppBreadcrumb appRoutes={routes} router={router} /> */}
            <Container fluid>
              <Suspense fallback={this.loading()}>
                <Switch>
                  {routes.map((route, idx) => {
                    return route.component ? (
                      <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={(props) =>
                          localStorage.getItem("Psbtoken") != null ? (
                            <route.component {...props} />
                          ) : (
                            <Redirect from="/" to="/login" />
                          )
                        }
                      />
                    ) : null;
                  })}
                  <Redirect from="/" to="/landingpage" />
                </Switch>
              </Suspense>
            </Container>
          </main>
          <AppAside fixed>
            <Suspense fallback={this.loading()}>
              <DefaultAside />
            </Suspense>
          </AppAside>
        </div>
        {/* <AppFooter>
          <Suspense fallback={this.loading()}>
            <DefaultFooter />
          </Suspense>
        </AppFooter> */}
      </div>
    );
  }
}

function bindAction(dispatch) {
  return {
    logoutaction: () => {
      dispatch(logOut());
    },
    fetchGetEquation: () => {
      dispatch(fetchGetEquation());
    },
    addGeneralInformation: () => {
      dispatch(Action.addGeneralInformation({}));
    },
  };
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, bindAction)(DefaultLayout);
